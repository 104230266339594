var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","loading":_vm.loading,"height":"100%","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('customer.orders.defineApprovalRound'))+" ")]),_c('v-form',{attrs:{"disabled":_vm.loading}},[_c('v-row',[(_vm.isManualCreationMode)?[_c('v-col',{attrs:{"lg":6,"md":12}},[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-title font-weight-semibold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('customer.orders.defineOrderApprovalRound'))+" ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('customer.orders.orderApprovalWorkflow')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"value":_vm.value.orderApprovalWorkflowId,"items":_vm.accountWorkflows.filter(
                      (item) => item.type === 'order-approval-workflow'
                    ),"label":_vm.$t('customer.orders.orderApprovalWorkflow'),"item-text":"name","item-value":"_id","placeholder":_vm.$t('customer.orders.selectApprovalWorkflow'),"clearable":!_vm.readonly,"error-messages":errors,"readonly":_vm.readonly},on:{"change":_vm.onSelectedOrderApprovalWorkflowChange},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width"},[_c('div',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(item.name))]),_c('cz-chip',{staticStyle:{"max-width":"auto"},attrs:{"color":"primary","circle-edges":"","small":"","text":_vm.$t('customer.workflowManagementPage.stepsCount', [
                            item.steps.length
                          ])}})],1)]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width py-4",staticStyle:{"column-gap":"20px"}},[_c('div',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(item.name))]),_c('cz-chip',{staticStyle:{"max-width":"auto"},attrs:{"color":"primary","circle-edges":"","small":"","text":_vm.$t('customer.workflowManagementPage.stepsCount', [
                            item.steps.length
                          ])}})],1)]}}],null,true)})]}}],null,false,152020849)}),(_vm.value.orderApprovalWorkflowSteps.length)?_c('v-stepper',{attrs:{"outlined":"","flat":"","color":"primary","alt-labels":""}},[_c('v-stepper-header',{staticClass:"flex-nowrap"},[_vm._l((_vm.value.orderApprovalWorkflowSteps),function(step,index){return [_c('v-stepper-step',{key:step.accountWorkflowStepId,attrs:{"icon":_vm.mdiCheck,"step":step.stepNumber,"complete-icon":step.approverUserId ? _vm.mdiCheck : _vm.mdiCancel,"complete":"","color":"primary"}},[_vm._v(" "+_vm._s(step.stepTitle)+" ")]),(
                        index < _vm.value.orderApprovalWorkflowSteps.length - 1
                      )?_c('v-divider',{key:`${step.accountWorkflowStepId}_divider`}):_vm._e()]})],2)],1):_vm._e(),_c('v-form',{staticClass:"position-relative",attrs:{"disabled":_vm.loading}},[_c('v-row',{staticClass:"overflow-x-hidden mt-4",attrs:{"no-gutters":""}},_vm._l((_vm.value.orderApprovalWorkflowSteps),function(step){return _c('v-col',{key:step._id,attrs:{"lg":12,"md":12,"sm":12}},[_c('cz-form-field',{attrs:{"label":step.stepTitle,"required":!_vm.readonly}},[_c('validation-provider',{attrs:{"rules":"required","name":step.stepTitle},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"value":step.approverUserId,"error-messages":errors,"outlined":"","items":_vm.getApproverForWorkflowStep(step),"item-text":"fullName","item-value":"_id","placeholder":step.approverRole === 'customer-authorizer'
                              ? _vm.$t('customer.orders.selectOrderAuthorizer')
                              : _vm.$t('customer.orders.selectOrderPurchaser'),"clearable":!_vm.readonly,"readonly":_vm.readonly},on:{"change":function($event){return _vm.onOrderApproverUserChanged($event, step)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"column-gap":"20px"}},[_c('cz-avatar',{staticClass:"mx-0",attrs:{"size":35,"color":"primary","name":item.fullName,"initials-color":"white","initials-size":"font-weight-semibold text-subtitle-1 text-uppercase"}}),_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.fullName))])],1)]}},{key:"item",fn:function({ item }){return [_c('v-list-item-avatar',[_c('cz-avatar',{attrs:{"size":35,"color":"primary","name":item.fullName,"initials-color":"white","initials-size":"font-weight-semibold text-subtitle-1 text-uppercase"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}}],null,true)})]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)]:_vm._e(),_c('v-col',{attrs:{"lg":6,"md":12}},[_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',{staticClass:"text-title font-weight-semibold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('customer.orders.defineOrderSubmissionApprovalRound'))+" ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('customer.orders.orderApprovalWorkflow')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"value":_vm.value.orderSubmissionWorkflowId,"items":_vm.accountWorkflows.filter(
                    (item) => item.type === 'order-submission-workflow'
                  ),"label":_vm.$t('customer.orders.orderApprovalWorkflow'),"item-text":"name","item-value":"_id","clearable":!_vm.readonly,"placeholder":_vm.$t('customer.orders.selectApprovalWorkflow'),"error-messages":errors,"readonly":_vm.readonly},on:{"change":_vm.onSelectedOrderSubmissionlWorkflowChange},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width"},[_c('div',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(item.name))]),_c('cz-chip',{staticStyle:{"max-width":"auto"},attrs:{"color":"primary","circle-edges":"","small":"","text":_vm.$t('customer.workflowManagementPage.stepsCount', [
                          item.steps.length
                        ])}})],1)]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width py-4",staticStyle:{"column-gap":"20px"}},[_c('div',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(item.name))]),_c('cz-chip',{staticStyle:{"max-width":"auto"},attrs:{"color":"primary","circle-edges":"","small":"","text":_vm.$t('customer.workflowManagementPage.stepsCount', [
                          item.steps.length
                        ])}})],1)]}}],null,true)})]}}])}),(_vm.value.orderSubmissionWorkflowSteps.length)?_c('v-stepper',{attrs:{"outlined":"","flat":"","width":"100%","color":"primary","alt-labels":""}},[_c('v-stepper-header',{staticClass:"flex-nowrap"},[_vm._l((_vm.value.orderSubmissionWorkflowSteps),function(step,index){return [_c('v-stepper-step',{key:step.accountWorkflowStepId,attrs:{"icon":_vm.mdiCheck,"step":step.stepNumber,"complete-icon":step.approverUserId ? _vm.mdiCheck : _vm.mdiCancel,"complete":"","color":"primary"}},[_vm._v(" "+_vm._s(step.stepTitle)+" ")]),(
                      index < _vm.value.orderSubmissionWorkflowSteps.length - 1
                    )?_c('v-divider',{key:`${step.accountWorkflowStepId}_divider`}):_vm._e()]})],2)],1):_vm._e(),_c('v-form',{staticClass:"position-relative",attrs:{"disabled":_vm.loading}},[_c('v-row',{staticClass:"overflow-x-hidden mt-4",attrs:{"no-gutters":""}},_vm._l((_vm.value.orderSubmissionWorkflowSteps),function(step){return _c('v-col',{key:step._id,attrs:{"lg":12,"md":12,"sm":12}},[_c('cz-form-field',{attrs:{"label":step.stepTitle,"required":!_vm.readonly}},[_c('validation-provider',{attrs:{"rules":"required","name":step.stepTitle},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"value":step.approverUserId,"error-messages":errors,"items":_vm.getApproverForWorkflowStep(step),"item-text":"fullName","item-value":"_id","placeholder":step.approverRole === 'customer-authorizer'
                            ? _vm.$t('customer.orders.selectOrderAuthorizer')
                            : _vm.$t('customer.orders.selectOrderPurchaser'),"clearable":!_vm.readonly,"readonly":_vm.readonly},on:{"change":function($event){return _vm.onOrderSubmissionApproverUserChanged($event, step)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"column-gap":"20px"}},[_c('cz-avatar',{staticClass:"mx-0",attrs:{"size":35,"color":"primary","name":item.fullName,"initials-color":"white","initials-size":"font-weight-semibold text-subtitle-1 text-uppercase"}}),_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.fullName))])],1)]}},{key:"item",fn:function({ item }){return [_c('v-list-item-avatar',[_c('cz-avatar',{attrs:{"size":35,"color":"primary","name":item.fullName,"initials-color":"white","initials-size":"font-weight-semibold text-subtitle-1 text-uppercase"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}}],null,true)})]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }